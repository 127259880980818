import React from 'react';
import PropTypes from 'prop-types';

import { EmptyComponent } from 'components/shared';

import Carousel from './Carousel';

const CAROUSEL_DELAY = 10 * 1000; // Be sure to sync in pd-dashboard.sass

export default class Carousels extends React.Component {
  static propTypes = {
    carousels: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.activeCarouselRef = React.createRef();
    this.contentRef = React.createRef();
  }

  state = {
    activeCarousel: -1,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.activeCarousel !== -1 || nextProps.carousels.length < 1) {
      return null;
    }
    const activeCarousel = 0;
    return {
      ...prevState,
      activeCarousel,
    };
  }

  componentDidMount() {
    this.updateContentHeight();
    if (this.state.activeCarousel !== -1 && this.props.carousels.length > 1) {
      this.beginCycling();
    }
  }

  componentDidUpdate(prevState) {
    if (
      !this.carouselTimer &&
      prevState.activeCarousel === -1 &&
      this.state.activeCarousel !== -1 &&
      this.props.carousels.length > 1
    ) {
      this.beginCycling();
    }
    this.updateContentHeight();
  }

  componentWillUnmount() {
    this.endCycling();
  }

  updateContentHeight() {
    const activeCarouselRef = this.activeCarouselRef.current;
    if (!activeCarouselRef) {
      return;
    }
    const contentRef = this.contentRef.current;
    contentRef.style.height = `${activeCarouselRef.clientHeight}px`;
  }

  beginCycling() {
    this.carouselTimer = setInterval(this.cycleCarousel, CAROUSEL_DELAY);
  }

  endCycling() {
    clearInterval(this.carouselTimer);
  }

  cycleCarousel = () => {
    const activeCarousel = (this.state.activeCarousel + 1) % this.props.carousels.length;
    this.setState({
      activeCarousel,
    });
  };

  navigateTo(index) {
    this.endCycling();
    this.setState({
      activeCarousel: index,
    });
    this.beginCycling();
  }

  render() {
    const { carousels } = this.props;
    const empty = carousels.length < 1;
    return (
      <div className="white-soft-box2 white-soft-box2--no-animate pd-dashboard-box">
        <h2 className="page-header page-header--no-bottom-margin">This Week in PD</h2>
        {empty ? (
          <EmptyComponent subtitle="Nothing for now! Check back later." />
        ) : (
          <div>
            <div className="pd-dashboard-content" ref={this.contentRef}>
              {carousels.map((carousel, index) => (
                <Carousel
                  carousel={carousel}
                  key={carousel.id}
                  visible={index === this.state.activeCarousel}
                  ref={index === this.state.activeCarousel ? this.activeCarouselRef : null}
                  carouselSize={carousels.length}
                />
              ))}
            </div>
            <div className="pd-dashboard-carousel__nav">
              {carousels.map((carousel, index) => {
                let className = 'pd-dashboard-carousel__nav-button';
                if (index === this.state.activeCarousel) {
                  className += ' pd-dashboard-carousel__nav-button--active';
                }
                return (
                  <div
                    key={carousel.id}
                    className={className}
                    onClick={() => this.navigateTo(index)}
                  />
                );
              })}
            </div>
            <div className="center">
              <a href={window.URLS['committee:week_in_pd']()} className="btn btn-default">
                View All
              </a>
            </div>
          </div>
        )}
      </div>
    );
  }
}
