import React from 'react';
import { connect } from 'react-redux';

import { userPropTypes } from 'helpers/proptypes';
import { requestCurrentUser } from 'store/actions/accounts/user';

import { EventInvitations } from 'api';

export class EventInvitationsCalloutUnwrapped extends React.Component {
  static propTypes = {
    user: userPropTypes,
  };

  state = {
    unreadInvitations: [],
  };

  componentDidMount() {
    this.fetchInvitations();
  }

  componentDidUpdate(prevProps) {
    if ((!prevProps.user && this.props.user) || this.props.user.pk !== prevProps.user.pk) {
      this.fetchInvitations();
    }
  }

  async fetchInvitations() {
    if (!this.props.user || !this.props.user.pk) {
      return;
    }
    const unreadInvitations = await EventInvitations.get(undefined, this.props.user.pk, true);
    this.setState({ unreadInvitations });
  }

  render() {
    if (this.state.unreadInvitations.length === 0) {
      return null;
    }
    const invitationsWord =
      this.state.unreadInvitations.length === 1 ? 'invitation' : 'invitations';
    return (
      <div className="alert alert-info pd-dashboard-callout actionable-alert">
        <p>
          <span className="fa fa-envelope fa-fw fa-lg icon-space-r" />
          You have{' '}
          <span className="badge employee-card--badge-pending">
            {this.state.unreadInvitations.length}
          </span>{' '}
          pending {invitationsWord}
        </p>
        <div className="actionable-alert__action">
          <a href={window.URLS['pd:event_invitation_list']()} className="btn btn-default">
            View
            <span className="fa fa-chevron-right icon-space-l" />
          </a>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ accountsUser }) => {
  return {
    user: accountsUser[accountsUser.current],
  };
};

const EventInvitationsCallout = connect(
  mapStateToProps,
  { requestCurrentUser }
)(EventInvitationsCalloutUnwrapped);

export default EventInvitationsCallout;
