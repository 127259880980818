import React from 'react';
import { PinraEvents } from '../../api';
import PinraForm from './PinraForm';
import { Alert, Loading } from 'components';
import { failedAssertion } from 'helpers/reporting';
import PropTypes from 'prop-types';

export default class ManagerPinra extends React.Component {
  static propTypes = {
    user: PropTypes.any,
  };

  state = {
    listLoading: true,
    listError: false,
    pinras: [],
    currentUser: '',
  };

  async componentDidMount() {
    try {
      PinraEvents.get(this.props.user, 'False').then((res) => {
        this.setState({ pinras: res, listLoading: false, listError: false });
      });
    } catch (err) {
      failedAssertion(err);
      this.setState({ listError: true, listLoading: false });
    }
  }

  render() {
    if (!this.state.pinras || !this.state.pinras.length) {
      return '';
    }
    if (this.state.listError) {
      return (
        <div className="col-xs-12">
          <Alert type="danger" icon="fa-exclamation-triangle">
            Something went wrong. Our team has been notified, please try again or check back later.
          </Alert>
        </div>
      );
    }
    if (this.state.listLoading) {
      return (
        <div className="col-xs-12 white-soft-box2 white-soft-box2--no-animate white-soft-box2--connected-above">
          <h1>PINRA's</h1>
          <hr />
          <div className="row">
            <div className="col-xs-12">
              <Loading />
            </div>
          </div>
        </div>
      );
    }
    return (
      <div>
        <div className="white-soft-box2 white-soft-box2--no-animate pd-dashboard-box">
          <h2 className="page-header page-header--no-bottom-margin">PINRA's</h2>
          <div className="pd-dashboard-content">
            <div style={{ borderBottom: '1px solid #e0e0e0' }}>
              {this.state.pinras.map((pinra) => (
                <div key={pinra.id}>
                  <PinraForm pinra={pinra} />
                </div>
              ))}
            </div>
          </div>
          <div className="center">
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSej5ANpLPVOUz8xAtDSZH8ol3SkQFbCB8A0Gr0JMJzuNnsqWw/viewform?usp=sf_link​"
              target="_blank"
              className="btn btn-default"
            >
              Upload Additional Documentation
            </a>
          </div>
        </div>
      </div>
    );
  }
}
