import React from 'react';
import PropTypes from 'prop-types';

import { carouselPropTypes } from 'helpers/proptypes';

class Carousel extends React.PureComponent {
  static propTypes = {
    carousel: carouselPropTypes.isRequired,
    visible: PropTypes.bool.isRequired,
    carouselSize: PropTypes.number.isRequired,
    forwardedRef: PropTypes.object,
  };

  state = {
    previouslyVisible: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.visible) {
      return {
        ...prevState,
        previouslyVisible: true,
      };
    }
    return null;
  }

  render() {
    const {
      title,
      description,
      button_link: buttonLink,
      button_text: buttonText,
    } = this.props.carousel;
    const { visible, forwardedRef, carouselSize } = this.props;
    let className = 'pd-dashboard-carousel';
    if (visible) {
      if (carouselSize === 1) {
        className += ' pd-dashboard-carousel--show-single';
      } else {
        className += ' pd-dashboard-carousel--show';
      }
    } else if (this.state.previouslyVisible) {
      className += ' pd-dashboard-carousel--hide';
    }
    return (
      <div className={className} ref={forwardedRef}>
        <div className="pd-dashboard-row__content">
          <div className="pd-dashboard-carousel__title">{title}</div>
          <div dangerouslySetInnerHTML={{ __html: description }} />
          {buttonLink && (
            <div className="center">
              <a href={buttonLink} className="btn btn-primary btn-sm btn-sm--alt mt1">
                {buttonText}
              </a>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const ForwardedCarousel = React.forwardRef((props, ref) => {
  return <Carousel {...props} forwardedRef={ref} />;
});

export default ForwardedCarousel;
