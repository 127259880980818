import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { EventDictionary } from 'helpers/dictionaries';
import { RegistrationStatusLabel } from 'components';
import { EmptyComponent } from 'components/shared';

import { EventStatus } from 'components/events/components';
import EventInvitationsCallout from './EventInvitationsCallout';

const { REG_STATUS } = EventDictionary;

const compareEvents = (now) => (left, right) => {
  const diff = left.event.first_date.diff(right.event.first_date);

  const leftDenied = left.status === REG_STATUS.DENIED;
  const rightDenied = right.status === REG_STATUS.DENIED;
  if (leftDenied && !rightDenied) {
    return 1;
  } else if (!leftDenied && rightDenied) {
    return -1;
  }

  const leftEnded = left.event.last_date.isBefore(now);
  const rightEnded = right.event.last_date.isBefore(now);
  if (leftEnded && !rightEnded) {
    return 1;
  } else if (!leftEnded && rightEnded) {
    return -1;
  }

  return diff;
};

export default class RegisteredEvents extends Component {
  static propTypes = {
    registrations: PropTypes.array.isRequired,
  };

  render() {
    let registrations = this.props.registrations;
    registrations.map((reg) => {
      reg.event.first_date = moment(reg.event.first);
      reg.event.last_date = moment(reg.event.last);
    });

    const MIN_DATE = moment().add(-30, 'days');
    const MAX_DATE = moment().add(30, 'days');
    registrations = registrations.filter(
      (reg) =>
        !(
          reg.event.last_date.isBefore(MIN_DATE) ||
          reg.event.first_date.isAfter(MAX_DATE) ||
          reg.event.canceled ||
          reg.status === REG_STATUS.WITHDRAW
        )
    );
    const now = moment();
    registrations.sort(compareEvents(now));

    let additionalEvents = 0;
    if (registrations.length > 6) {
      // Only start splitting once there's > 1 extra event
      additionalEvents = registrations.length - 5;
      registrations = registrations.slice(0, 5);
    }
    const empty = registrations.length < 1;

    return (
      <div className="white-soft-box2 white-soft-box2--no-animate pd-dashboard-box">
        <Link to={window.URLS['pd:event_explore']()}>
          <button className="btn btn-sm btn-outline-primary pull-right">Explore Events</button>
        </Link>
        <h2 className="page-header page-header--no-bottom-margin">Registered Events</h2>
        <EventInvitationsCallout />
        {empty ? (
          <EmptyComponent subtitle="You have no events starting in the next 30 days." />
        ) : (
          <div className="pd-dashboard-content">
            {registrations.map((reg) => (
              <div className="pd-dashboard-row" key={reg.pk}>
                <div
                  className={`pd-dashboard-row__content${
                    reg.event.canceled ? ' pd-dashboard-row__content--muted' : ''
                  }`}
                >
                  <div className="pd-dashboard-row__title">
                    <Link to={window.URLS['pd:event_detail'](reg.event.pk)}>{reg.event.title}</Link>
                  </div>
                  <div className="pd-dashboard-row__status">
                    <EventStatus firstDate={reg.event.first_date} lastDate={reg.event.last_date} />
                  </div>
                  {reg.event.times.map((time) => (
                    <div className="pd-dashbaord-row__time" key={time[0]}>
                      <div className="pd-dashbaord-row__date">
                        <span className="fa fa-clock-o icon-space-r" />
                        {time[0]}
                      </div>
                      <div className="pd-dashbaord-row__location">
                        <span className="fa fa-map-marker icon-space-r" />
                        {time[2]}
                      </div>
                    </div>
                  ))}
                  <div className="pd-dashboard-row__reg-status">
                    <RegistrationStatusLabel status={reg.status} />
                    {reg.event.canceled && (
                      <span className="label label-danger icon-space-l icon-space-r">
                        <i className="fa fa-times icon-space-r" />
                        Canceled
                      </span>
                    )}
                    {reg.event.private && (
                      <span className="label label-dark-blue icon-space-l icon-space-r">
                        <i className="fa fa-user-secret icon-space-r" />
                        Private
                      </span>
                    )}
                  </div>
                </div>
              </div>
            ))}
            {additionalEvents > 0 && (
              <div className="pd-dashboard-row pd-dashboard-row--additional-content">
                {additionalEvents} more events not shown
              </div>
            )}
          </div>
        )}
        <div className="center">
          <a href={window.URLS['accounts:registrations']()} className="btn btn-default">
            View All Registrations
          </a>
        </div>
      </div>
    );
  }
}
