import React from 'react';
import { PinraEvents } from '../../api';
import { failedAssertion } from 'helpers/reporting';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap-modal';

export default class PinraForm extends React.Component {
  static propTypes = {
    pinra: PropTypes.object,
    activity_name: PropTypes.string,
    date_times_attending: PropTypes.string,
    status: PropTypes.string,
  };

  state = {
    status: this.props.pinra.status,
    showWithdraw: false,
    showDrop: false,
    reason: '',
  };

  showWithdrawModal = () => this.setState({ showWithdraw: true });
  hideWithdrawModal = () => this.setState({ showWithdraw: false });

  showDropModal = () => this.setState({ showDrop: true });
  hideDropModal = () => this.setState({ showDrop: false });

  updateStatus(event, _status, id) {
    event.preventDefault();
    this.hideWithdrawModal();
    this.hideDropModal();
    this.setState({ status: _status }, () => {
      try {
        PinraEvents.updateStatus({
          id: id,
          status: this.state.status,
          reason: this.state.reason,
        });
        window.show_stack_topleft('Success', 'Pinra Status has been updated!', 'success');
      } catch (err) {
        failedAssertion(err);
      }
    });
  }

  finishPinra = (_status, id) => {
    this.setState({ status: _status }, () => {
      try {
        PinraEvents.updateStatus({
          id: id,
          status: this.state.status,
        });
        window.show_stack_topleft('Success', 'Pinra Status has been updated!', 'success');
      } catch (err) {
        failedAssertion(err);
      }
    });
    var win = window.open(
      'https://docs.google.com/forms/d/e/1FAIpQLSej5ANpLPVOUz8xAtDSZH8ol3SkQFbCB8A0Gr0JMJzuNnsqWw/viewform?usp=sf_link​',
      '_blank'
    );
    win.focus();
  };

  handleChange = (event) => this.setState({ [event.target.name]: event.target.value });

  render() {
    return (
      <div>
        <div className="pd-dashboard-row__title" style={{ color: '#F76902', marginTop: 10 }}>
          {this.props.pinra.activity_name}
        </div>
        <div className="pd-dashboard-row__time">
          <div className="pd-dashboard-row__date">
            <span className="fa fa-clock-o icon-space-r" />
            {this.props.pinra.date_times_attending}
          </div>
        </div>
        <div className="row" style={{ marginBottom: 25 }}>
          {(() => {
            if (this.state.status === '0')
              return (
                <div>
                  <div className="pd-dashboard-row__status pull-left">
                    This PINRA has been dropped and will disappear on the next page reload.
                  </div>
                </div>
              );
            if (this.state.status === '1')
              return (
                <div>
                  <div className="pd-dashboard-row__status pull-left">
                    Waiting on sponsor approval to attend event
                  </div>
                </div>
              );
            if (this.state.status === '2')
              return (
                <div className="pd-dashboard-row__status pull-left">
                  Waiting for you to attend your event
                </div>
              );
            if (this.state.status === '3')
              return (
                <div className="pd-dashboard-row__status pull-left">
                  Waiting for you to upload final documents
                  <br />
                </div>
              );
            if (this.state.status === '4')
              return (
                <div className="pd-dashboard-row__status pull-left">
                  Waiting for sponsor final approval
                </div>
              );
            if (this.state.status === '5')
              return (
                <div className="pd-dashboard-row__status">
                  This PINRA is complete and will disappear on the next page reload.
                </div>
              );
            if (this.state.status === '6')
              return (
                <div className="pd-dashboard-row__status">
                  This PINRA has been withdrawn and will disappear on the next page reload.
                </div>
              );
          })()}
          <br />
          {(() => {
            if (this.state.status === '1')
              return (
                <div className="pd-dashboard-row__action" style={{ marginTop: 10 }}>
                  <button
                    title="Dropping this event will remove it from your transcript"
                    className=" btn btn-danger"
                    onClick={this.showDropModal}
                  >
                    Drop PINRA
                  </button>
                </div>
              );
            if (this.state.status === '2')
              return (
                <div className="pd-dashboard-row__action" style={{ marginTop: 10 }}>
                  <button
                    title="You have satisfactorily attended this event"
                    className=" btn btn-primary"
                    onClick={(e) => this.updateStatus(e, '3', this.props.pinra.id)}
                  >
                    Done Attending Event
                  </button>
                  <button
                    title="Withdrawing from this event will give you an Incomplete on your transcript"
                    className=" btn btn-danger"
                    style={{ marginLeft: 10 }}
                    onClick={this.showWithdrawModal}
                  >
                    Withdraw PINRA
                  </button>
                </div>
              );
            if (this.state.status === '3')
              return (
                <div className="pd-dashboard-row__action" style={{ marginTop: 10 }}>
                  <button
                    title="Upload whatever documents you received from the event as proof you attended"
                    className="btn btn-secondary"
                    onClick={(e) => this.finishPinra('4', this.props.pinra.id)}
                  >
                    Upload Final Documents
                  </button>
                  <button
                    title="Withdrawing from this event will give you an Incomplete on your transcript"
                    className=" btn btn-danger"
                    style={{ marginLeft: 10 }}
                    onClick={this.showWithdrawModal}
                  >
                    Withdraw PINRA
                  </button>
                </div>
              );
            if (this.state.status === '4')
              return (
                <div className="pd-dashboard-row__action" style={{ marginTop: 10 }}>
                  <button
                    title="Withdrawing from this event will give you an Incomplete on your transcript"
                    className=" btn btn-danger"
                    onClick={this.showWithdrawModal}
                  >
                    Withdraw PINRA
                  </button>
                </div>
              );
          })()}
        </div>
        {/* model for dropping */}
        <Modal
          show={this.state.showDrop}
          onHide={this.hideDropModal}
          modalPrefix="bootstrap-modal modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Are you sure you wish to drop this PINRA?</Modal.Title>
          </Modal.Header>
          <Modal.Body>Dropping will remove this PINRA event from the system.</Modal.Body>
          <Modal.Footer>
            <button className="btn btn-warning" onClick={this.hideDropModal}>
              Cancel
            </button>
            <button
              className="btn btn-danger"
              onClick={(e) => this.updateStatus(e, '0', this.props.pinra.id)}
            >
              Confirm
            </button>
          </Modal.Footer>
        </Modal>
        {/* model for withdrawing */}
        <Modal
          show={this.state.showWithdraw}
          onHide={this.hideWithdrawModal}
          modalPrefix="bootstrap-modal modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Are you sure you wish to withdraw from this PINRA?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Withdrawing from this PINRA event will leave an 'Incomplete' on your transcript.
            <form>
              <label htmlFor="reason">Reason: </label>
              <textarea
                name="reason"
                cols="40"
                rows="10"
                placeholder="Reason for withdrawing"
                className="form-control"
                id="id_body"
                value={this.state.reason}
                onChange={this.handleChange}
              />
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-warning" onClick={this.hideWithdrawModal}>
              Cancel
            </button>
            <button
              className="btn btn-danger"
              onClick={(e) => this.updateStatus(e, '6', this.props.pinra.id)}
            >
              Confirm
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
