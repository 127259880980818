import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { requestCurrentEmployee } from 'store/actions/registrations';
import { carouselListThunk } from 'store/actions/carousels';

import { renderCalendar } from 'helpers/calendar';
import { userPropTypes, registrationPropTypes, carouselPropTypes } from 'helpers/proptypes';
import { Alert, Loading, Calendar, PageHeader } from 'components';

import RegisteredEvents from './RegisteredEvents';
import Carousels from './Carousels';
import Pinra from './Pinra';

export class PdDashboard extends React.Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    hasErrored: PropTypes.bool.isRequired,
    carouselListThunk: PropTypes.func.isRequired,
    carousels: PropTypes.arrayOf(carouselPropTypes).isRequired,
    requestCurrentEmployee: PropTypes.func.isRequired,
    user: userPropTypes,
    registrations: PropTypes.arrayOf(registrationPropTypes).isRequired,
    // upcomingEvents: PropTypes.array.isRequired,
  };

  componentDidMount() {
    this.props.carouselListThunk();
    this.props.requestCurrentEmployee();
  }

  componentDidUpdate() {
    renderCalendar();
  }

  render() {
    if (this.props.isLoading) {
      return <Loading />;
    }
    if (this.props.hasErrored) {
      return (
        <Alert type="danger" icon="fa-exclamation-triangle">
          Our apologies, an error occurred. Please reload, or try again later.
        </Alert>
      );
    }

    const avatar = this.props.user.profile.avatar;

    return (
      <div className="row">
        <div className="col-xs-12">
          <div className="white-soft-box2 white-soft-box2--no-animate">
            <PageHeader.WithAvatar src={avatar}>
              <PageHeader.Basic
                title="PD Dashboard"
                subtitle={this.props.user.name}
                actions={
                  <Link
                    to={window.URLS['accounts:public_profile_detail'](this.props.user.profile.slug)}
                    className="btn btn-default"
                  >
                    View Profile
                  </Link>
                }
              />
            </PageHeader.WithAvatar>
          </div>
        </div>
        <div className="col-xs-12">
          <div className="row">
            <div className="col-md-6">
              <Pinra user={this.props.user.profile.slug} />
              <RegisteredEvents registrations={this.props.registrations} />
              <div className="well">
                <p>
                  <strong>Quick Links:</strong>
                  <Link
                    to={window.URLS['accounts:pd_transcript'](this.props.user.profile.slug)}
                    className="pd-dashboard__link"
                  >
                    PD Transcript
                  </Link>
                  <a
                    href={window.URLS['pd:event_invitation_list']()}
                    className="pd-dashboard__link"
                  >
                    Event Invitations
                  </a>
                  <Link
                    to={window.URLS['accounts:public_profile_list']()}
                    className="pd-dashboard__link"
                  >
                    DAS Profiles
                  </Link>
                  {this.props.user.isManager && (
                    <Link
                      to={window.URLS['pd:event_manager_dashboard']()}
                      className="pd-dashboard__link"
                    >
                      Manager Dashboard
                    </Link>
                  )}
                  {this.props.user.isFacilitator && (
                    <a
                      href={window.URLS['pd:facilitator_dashboard']()}
                      className="pd-dashboard__link"
                    >
                      Facilitator Dashboard
                    </a>
                  )}
                </p>
                {this.props.user.isAdmin && (
                  <p>
                    <i className="fa fa-lock icon-space-r" />
                    <strong>Admin Links:</strong>
                    <Link
                      to={window.URLS['pd:event_admin_dashboard']()}
                      className="pd-dashboard__link"
                    >
                      Events Admin
                    </Link>
                    <a
                      href={window.URLS['committee:carousel_list']()}
                      className="pd-dashboard__link"
                    >
                      Carousel Admin
                    </a>
                    <a href={window.URLS['admin:index']()} className="pd-dashboard__link">
                      Admin Site
                    </a>
                  </p>
                )}
              </div>
            </div>

            <div className="col-md-6">
              <Carousels carousels={this.props.carousels} />

              {/* <div className="white-soft-box2 white-soft-box2--no-animate pd-dashboard-box">
                <h2 className="page-header page-header--no-bottom-margin mt0">Upcoming Events</h2>
                <div className="pd-dashboard-content">
                  {this.props.upcomingEvents.map((event) => (
                    <div className="pd-dashboard-row" key={event.title}>
                      <div>
                        <div className="pd-dashboard-row__title">
                          <Link to="foo">{event.title}</Link>
                        </div>
                        <div className="pd-dashboard-row__times">
                          <div className="pd-dashboard-row__time">
                            <span className="fa fa-clock-o icon-space-r" />{event.time}
                          </div>
                        </div>
                      </div>
                      <div>
                        <Link to="foo">
                          <button className="btn btn-default"><span className="fa fa-chevron-right" /></button>
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="center">
                  <a href={window.URLS['pd:calendar']()} className="btn btn-default">Calendar Events</a>
                </div>
              </div> */}
            </div>
          </div>
          <div className="row">
            <Calendar
              id="calendar"
              calendarType="cart"
              tooltipMessage="Displayed are events currently enrolled in,<br /> registration pending requests, and events<br /> in your shopping cart to show your current schedule"
              calendarTitle="Personal Schedule"
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ accountsUser, carousels, registrations }) => {
  return {
    isLoading: accountsUser.isLoading || carousels.isLoading || registrations.userLoading,
    hasErrored: accountsUser.hasErrored || carousels.hasErrored || registrations.error,
    carousels: carousels.carousels,
    user: accountsUser[accountsUser.current],
    registrations: registrations.user ? registrations.user.user_registrations : [],
    // upcomingEvents: pdDashboard.data.upcomingEvents || [],
  };
};

export default connect(
  mapStateToProps,
  {
    carouselListThunk,
    requestCurrentEmployee,
  }
)(PdDashboard);
